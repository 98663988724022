<template>
  <div>
    <a-card size="small" :bordered="false" style="height: 100%" :bodyStyle="{ height: '200px' }">
      <template slot="title">{{ $t("产品库存前十占比") }}</template>
      <div id="productStatsChart" style="height: 100%"></div>
    </a-card>
  </div>
</template>

<script>
import { materialTopTenList } from "@/api/statistic";
import { Pie } from "@antv/g2plot";

export default {
  data() {
    return {
      dataChart: null,
      dataItems: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    queryData() {
      materialTopTenList({ warehouse: this.currentWarehouse }).then((data) => {
        this.dataItems = data.results;
        this.dataChart.changeData(this.dataItems);
      });
    },
  },
  mounted() {
    this.dataChart = new Pie("productStatsChart", {
      data: [],
      autoFit: true,
      meta: {
        material_name: { alias: this.$t("产品"), range: [0, 1] },
        quantity: { alias: this.$t("数量") },
      },
      appendPadding: 10,
      angleField: "quantity",
      colorField: "material_name",
    });
    this.dataChart.render();

    this.queryData();
  },
};
</script>
